import hisWork from './hisWork'
import pun from './pun'
import rew from './rew'
import train from './train'
import worker from './worker'
import workerEdit from './workerEdit'
import workerEdu from './workerEdu'
import workerAuth from '../susong/worker_auth_fun'
import workerIn from './workerIn'
import workerOut from './workerOut'
import workerCertificate from './workerCertificate'
import workerCertificateDoctorPraCount from './workerCertificateDoctorPraCount'
import workerIdCard from './workerIdCard'
import workerJobTitle from './workerJobTitle'
import workerJobTitleCurrent from './workerJobTitleCurrent'
import jobTitleCurrent from './jobTitleCurrent'
import workerJobTitlePromotion from './workerJobTitlePromotion'
import workerJobTitleQueue from './workerJobTitleQueue'
import workerJobTitleQueueWarning from './workerJobTitleQueueWarning'
import count from './count'
import person from './person'
import agreement from './agreement'
import appraisal from './appraisal'
import wage from './wage'
import agreementWarning from './agreementWarning'
import agreementTurnWarning from './agreementTurnWarning'
import askLeave from './askLeave'
import askLeaveMy from './askLeaveMy'
import mySalary from './mySalary'
import certificate from './certificate'
import org from './org'
import orgType from './orgType'
import jobTitle from './jobTitle'
import placard from './placard'
import position from './position'
import fun from './fun'
import menu from './menu'
import role from './role'
import user from './user'
import conf_query from './conf_query'
import appraisalManage from './appraisalManage'
import infoRate from './infoRate'
import wageTongcheng from './wageTongcheng'
import operationLog from './operationLog'
import activity from './activity'
import orgInfo from './org_info'

import docManage from './docManage'
import docHandle from './docHandle'

import countBase from './countBase'
import countHome from './countHome'
import countCount from './countCount'

import wageJobTitle from './wageJobTitle'
import wageLevel from './wageLevel'
import performanceWorker from './performanceWorker'
import workerMonth from './workerMonth'
import workerSubsidy from './workerSubsidy'
import workerWage from './workerWage'
import workerWageJobTitle from './workerWageJobTitle'
import workerWageLevel from './workerWageLevel'
import reportNucleic from './report/nucleic'
import workerBatchEdit from './workerBatchEdit'
import analysisAge from './analysis/age'
import analysisMedical from './analysis/medical'
import askLeaveApply from './askLeaveApply'
import askLeaveApplyRecrod from './askLeaveApplyRecrod'
import askLeaveHome from './askLeaveHome'
import evaluation from './evaluation/index'
import appDoc from './app/doc/index'
import appVacate from './app/vacate/index'
import askLeaveApplyType from './askLeaveApplyType'

export default [{
  routerNames: ['count.base'],
  funs: countBase
},
{
  routerNames: ['count.home'],
  funs: countHome
},
{
  routerNames: ['count.count'],
  funs: countCount
},
{
  routerNames: ['doc.manage'],
  funs: docManage
},
{
  routerNames: ['doc.handle', 'wap.doc.handle', 'wap.doc.step'],
  funs: docHandle
},
{
  routerNames: ['performance.worker_month'],
  funs: workerMonth
},
{
  routerNames: ['performance.worker_subsidy'],
  funs: workerSubsidy
},
{
  routerNames: ['performance.worker_wage'],
  funs: workerWage
},
{
  routerNames: ['performance.worker_wage_job_title'],
  funs: workerWageJobTitle
},
{
  routerNames: ['performance.worker_wage_level'],
  funs: workerWageLevel
},
{
  routerNames: ['performance.wage_job_title'],
  funs: wageJobTitle
},
{
  routerNames: ['performance.wage_level'],
  funs: wageLevel
},
{
  routerNames: ['performance.worker'],
  funs: performanceWorker
},
{
  routerNames: ['activity.bus', 'activity.edu', 'activity.train'],
  funs: activity
},
{
  routerNames: ['base.orginfo'],
  funs: orgInfo
},
{
  routerNames: ['worker.inforate'],
  funs: infoRate
},
{
  routerNames: ['worker.worker_idcard'],
  funs: workerIdCard
},
{
  routerNames: ['worker.agreement_turn_warning'],
  funs: agreementTurnWarning
},
{
  routerNames: ['authority.user'],
  funs: user
},
{
  routerNames: ['authority.operation_log'],
  funs: operationLog
},
{
  routerNames: ['authority.role'],
  funs: role
},
{
  routerNames: ['authority.menu'],
  funs: menu
},
{
  routerNames: ['authority.fun'],
  funs: fun
},
{
  routerNames: ['conf.conf_query'],
  funs: conf_query
},
{
  routerNames: ['base.job_title'],
  funs: jobTitle
},
{
  routerNames: ['base.position'],
  funs: position
},
{
  routerNames: ['base.org_type'],
  funs: orgType
},
{
  routerNames: ['base.org'],
  funs: org
},
{
  routerNames: ['base.placard'],
  funs: placard
},
{
  routerNames: ['base.certificate'],
  funs: certificate
},
{
  routerNames: ['ask_leave.home'],
  funs: askLeaveHome
},
{
  routerNames: ['ask_leave.apply'],
  funs: askLeaveApply
},
{
  routerNames: ['ask_leave.apply_assign'],
  funs: askLeaveApply
},
{
  routerNames: ['ask_leave.apply_record'],
  funs: askLeaveApplyRecrod
},
{
  routerNames: ['ask_leave.my'],
  funs: askLeaveMy
},
{
  routerNames: ['ask_leave.record'],
  funs: askLeave
},
{
  routerNames: ['ask_leave.switch_config'],
  funs: askLeaveApplyType
},
{
  routerNames: ['worker.worker_certificate_doctor_pra_count'],
  funs: workerCertificateDoctorPraCount
},
{
  routerNames: ['worker.worker_certificate', 'worker.worker_certificate_doctor_qua'],
  funs: workerCertificate
},
{
  routerNames: ['worker.worker_certificate_doctor_pra'],
  funs: Object.assign({
    changelog: {
      funKey: 'changelog',
      name: '变更记录'
    }
  }, workerCertificate)
},
{
  routerNames: ['worker.worker_certificate_pro_hire'],
  funs: Object.assign({
    xp: {
      funKey: 'xp',
      name: '续聘',
      apis: [{
        path: '/basis/worker_certificate/{id}',
        method: 'PUT'
      }]
    }
  }, workerCertificate)
},
{
  routerNames: ['worker.worker_certificate_other'],
  funs: Object.assign({}, workerCertificate)
},
{
  routerNames: ['worker.agreement_warning'],
  funs: agreementWarning
},
{
  routerNames: ['worker.wage'],
  funs: wage
},
{
  routerNames: ['worker.wage_tongcheng'],
  funs: wageTongcheng
},
{
  routerNames: ['worker.worker_job_title'],
  funs: workerJobTitle
},
{
  routerNames: ['worker.worker_job_current'],
  funs: workerJobTitleCurrent
},
{
  routerNames: ['worker.job_title_current'],
  funs: jobTitleCurrent
},
{
  routerNames: ['worker.job_title_promotion'],
  funs: workerJobTitlePromotion
},
{
  routerNames: ['worker.job_title_queue'],
  funs: workerJobTitleQueue
},
{
  routerNames: ['worker.job_title_queue_warning'],
  funs: workerJobTitleQueueWarning
},
{
  routerNames: ['appraisal.ready', 'appraisal.doing', 'appraisal.checking', 'appraisal.vote', 'appraisal.question', 'appraisal.my', 'wap.my', 'wap.question', 'wap.vote', 'wap.checking', 'screen'],
  funs: appraisal
},
{
  routerNames: ['appraisal.manage'],
  funs: appraisalManage
},
{
  routerNames: ['wap.salary', 'wap.salary_tongcheng'],
  funs: mySalary
},
{
  routerNames: ['worker.agreement'],
  funs: agreement
},
{
  routerNames: ['person.index'],
  funs: person
},
{
  routerNames: ['worker.count'],
  funs: count
},
{
  routerNames: ['worker.worker_out'],
  funs: workerOut
},
{
  routerNames: ['worker.worker_in'],
  funs: workerIn
},
{
  routerNames: ['worker.train'],
  funs: train
},
{
  routerNames: ['worker.rew'],
  funs: rew
},
{
  routerNames: ['worker.pun'],
  funs: pun
},
{
  routerNames: ['worker.his_work'],
  funs: hisWork
},
{
  routerNames: ['worker.worker'],
  funs: worker
},
{
  routerNames: ['worker.edit'],
  funs: workerBatchEdit
},
{
  routerNames: ['worker.worker_edit'],
  funs: workerEdit
},
{
  routerNames: ['worker.worker_edu'],
  funs: workerEdu
},
{
  routerNames: ['worker.worker_auth'],
  funs: workerAuth
},
{
  routerNames: ['wap.menu', 'wap.salary', 'wap.salary_tongcheng'],
  funs: {
    getSalary: {
      funKey: 'getSalary',
      name: '获取上月工资明细',
      apis: [{
        path: '/basis/wage',
        method: 'GET'
      }]
    }
  }
}, {
  routerNames: [
    'report.nucleic.data',
    'report.nucleic.result',
    'report.nucleic.print',
    'report.nucleic.counts'
  ],
  funs: reportNucleic
}, {
  routerNames: ['analysis.age'],
  funs: analysisAge
}, {
  routerNames: ['analysis.medical'],
  funs: analysisMedical
}, {
  routerNames: ['evaluation.index', 'evaluation.details', 'evaluation.details.draw', 'evaluation.details.opinion', 'evaluation.details.approve'],
  funs: evaluation
}, {
  routerNames: ['/pages/doc/index', '/pages/doc/manage'],
  funs: appDoc
}, {
  routerNames: ['/pages/vacate/index?type=my', '/pages/vacate/index?type=distribution', '/pages/vacate/index?type=approval', '/pages/vacate/record?type=terminate'],
  funs: appVacate
}]